import React, { useEffect, useState } from "react";
// import MyNavbar from "../../commonComponents/Navbar/MyNavbar";
import "./checkSlots.css";
import { Col, Row } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format, addDays } from "date-fns";
import SuccessModal from "../../commonComponents/Modal/SuccessModal";
import { useParams } from "react-router-dom";
// import swal from "sweetalert"
import Swal from "sweetalert2";
const CheckSlots = () => {
  const {ground_id} = useParams();
  console.log(ground_id)
  const [selectedDate, setSelectedDate] = useState(null);
  const [slotBooked, setSlotBooked] = useState(false);
  const date = new Date().toLocaleDateString();
  
  const handleModalClose = () =>{
    setSlotBooked(false);
  }

  const [slot1Team1, setSlot1Team1] = useState({
    Team1name:"",
    Team2Name:"",
    Team1CaptainName:"",
    Team1CaptNumber:"",
    Team2CaptainName:"",
    Team2CaptNumber:""
  })
  const handleSlot1 = (e) =>{
    const {name , value} = e.target;
    setSlot1Team1((prevData)=>({
      ...prevData,
      [name]:value
    }
  )
    )
  }
  

  const [slot2Team1Name, setSlot2Team1Name] = useState("");
  const [slot2Team2Name, setSlot2Team2Name] = useState("");
  const [slot3Team1Name, setSlot3Team1Name] = useState("");
  const [slot3Team2Name, setSlot3Team2Name] = useState("");
  const [slot4Team1Name, setSlot4Team1Name] = useState("");
  const [slot4Team2Name, setSlot4Team2Name] = useState("");

  const handleBookSlot = (slot) => {
    // Prepare the data to send to the API
    let team1Name,team2Name, team1CaptainName,team1CaptNumber,team2CaptainName,team2CaptNumber;
    switch (slot) {
      case 1:
        team1Name = slot1Team1.Team1name;
        team2Name = slot1Team1.Team2Name;
        team1CaptainName=slot1Team1.Team1CaptainName;
      team1CaptNumber=slot1Team1.Team1CaptNumber;
      team2CaptainName=slot1Team1.Team2CaptainName;
      team2CaptNumber=slot1Team1.Team2CaptNumber;
        break;
      case 2:
        team1Name = slot2Team1Name;
        team2Name = slot2Team2Name;
        break;
     case 3:
      team1Name = slot3Team1Name;
      team2Name = slot3Team2Name;
      break;
      case 4:
      team1Name = slot4Team1Name;
      team2Name = slot4Team2Name;
      break;
     
      default:
        // Default case if slot number doesn't match any case
        break;
    }
    const data = {
      date: selectedDate.toISOString(), // Convert date to ISO string format
      slot: slot,
      ground_id:ground_id,
      team1Name: team1Name,
      team2Name: team2Name,
      team1CaptainName:team1CaptainName,
      team1CaptNumber:team1CaptNumber,
      team2CaptainName:team2CaptainName,
      team2CaptNumber:team2CaptNumber
    };

    // Call the API using fetch
    fetch('https://new.bookmygrounds.in/booking', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)

    })
    .then(response => {
      if (response.ok) {
        setSlotBooked(true);
        console.log('Slot booked successfully',slotBooked);
        Swal.fire({
          icon: 'success',
          title: 'Booking  Successful',
          text: `Slot ${slot}booked successfully for ${team1Name} and ${team2Name}`
        })
       
      } else {
        console.error('Failed to book slot');
        Swal.fire({
          icon: 'Error',
          title: 'Booking  Failed',
          text: `Slot ${slot}booked Failed for ${team1Name} and ${team2Name}`
        })
      }
    })
    .catch(error => {
      console.error('Error booking slot:', error);
      //setSuccessMessage('internal server error')
    });
  };
  const [showDetails, setShowDetails] = useState(false);
  const toggleDetails = () => setShowDetails(!showDetails);
  return (
    <>
      {/* <MyNavbar></MyNavbar> */}
      {/* {SuccessMessage && <div className="alert alert-success">{SuccessMessage}</div>} */}
      <Row className="date-tab text-center">
        <Col md={3} >
          <DatePicker
            className="border-less "
            selected={selectedDate}
            onChange={(date) => setSelectedDate(date)}
            dateFormat="EEE, MMM d, yyyy"
            // dateFormat="dd/MM/yyyy" // Format the date as needed
            isClearable
            placeholderText={format(new Date(), "EEE, MMM d, yyyy")}
          />
        </Col>
      </Row>
     

      <div class="container">
        <div className="row ">
          <div class="col-sm-12">
            <ul className="list-group">
              <li className="list-group-item-checkslots">
                <div className="slotName-container">
                <span className="lead font-weight-bold">
                  Slot 1 : 7:30AM to 10:30PM
                </span>
                <span className="">
                  <a>Edit SLot details</a>
                </span>
                </div>

                <div className="d-flex flex-sm-row mt-1">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Team 1 Name"      
                    name="Team1name"        
                    value={slot1Team1.Team1name}
                    onChange={handleSlot1}
                  
                  ></input>
                 
                  <small className="p-2 vs">vs</small>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Team 2 Name"
                    name="Team2Name"
                    value={slot1Team1.Team2Name}
                    onChange={handleSlot1}
                  ></input>
                </div>
                <Row>
                <Col className="text-center">
                  <button className="btn btn-sm btn-primary m-2" onClick={toggleDetails}>Add More Info</button>
            {showDetails && (
                <div className="details">
                    <input type="text" value={slot1Team1.Team1CaptainName} placeholder={`Team1  Captain Name`} />
                    <input type="text" value={slot1Team1.Team1CaptNumber} placeholder={`Team1  Captain Contact`} />
                    <input type="text" value={slot1Team1.Team2CaptainName} placeholder={`Team2  Captain Name`} />
                    <input type="text" value={slot1Team1.Team2CaptNumber} placeholder={`Team2  Captain Contact`} />
                </div>
                   )}
                  </Col>
                  <Col className="text-center">
                    <button className="btn btn-sm btn-primary m-2" onClick={() => handleBookSlot(1)}>Book</button>
                  </Col>
                
                </Row>
              </li>

              <li className="list-group-item-checkslots">
                <span className="lead font-weight-bold">
                  Slot 2 : 11AM to 2PM
                </span>
                <div className="d-flex flex-sm-row mt-1">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Team 1 Name"
                    value={slot2Team1Name}
                    onChange={(e) => setSlot2Team1Name(e.target.value)}
                  ></input>
                  <small className="p-2 vs">vs</small>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Team 2 Name"
                    value={slot2Team2Name}
                    onChange={(e) => setSlot2Team2Name(e.target.value)}
                  ></input>
                </div>
                <Row>
                  <Col className="text-center">
                    <button className="btn btn-sm btn-primary m-2" onClick={() => handleBookSlot(2)}>Book</button>
                  </Col>
                </Row>
              </li>

              <li className="list-group-item-checkslots">
                <span className="lead font-weight-bold">
                  Slot 3 : 2:30AM to 5:30PM
                </span>
                <div className="d-flex flex-sm-row mt-1">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Team 1 Name"
                    value={slot3Team1Name}
                    onChange={(e) => setSlot3Team1Name(e.target.value)}
                  ></input>
                  <small className="p-2 vs">vs</small>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Team 2 Name"
                    value={slot3Team2Name}
                    onChange={(e) => setSlot3Team2Name(e.target.value)}
                  ></input>
                </div>
                <Row>
                  <Col className="text-center">
                    <button className="btn btn-sm btn-primary m-2" onClick={() => handleBookSlot(3)}>Book</button>
                  </Col>
                </Row>                
              </li>
              <li className="list-group-item-checkslots">
                <span className="lead font-weight-bold">
                  Slot 4 : 6PM to 9:30PM
                </span>
                <div className="d-flex flex-sm-row mt-1">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Team 1 Name"
                    value={slot4Team1Name}
                    onChange={(e) => setSlot4Team1Name(e.target.value)}
                  ></input>
                  <small className="p-2 vs">vs</small>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Team 2 Name"
                    value={slot4Team2Name}
                    onChange={(e) => setSlot4Team2Name(e.target.value)}
                  ></input>
                </div>
                <Row>
                  <Col className="text-center">
                    <button className="btn btn-sm btn-primary m-2" onClick={() => handleBookSlot(4)}>Book</button>
                  </Col>
                </Row>
                </li>
            </ul>
          </div>
        </div>
      </div>
      {/* <SuccessModal show={slotBooked} onClose={handleModalClose}/> */}

    </>
  );
};
export default CheckSlots;
